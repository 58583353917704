<template>
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageaddheader :title="title" :title1="title1" :extraClass="'d-flex justify-content-between'">
          <template #right>
            <a-select v-model:value="user.accountNumber" show-search placeholder="Select a user" class="ant-select"
              style="width: 200px" :options="users" :filter-option="filterOption" @change="fetchUserData"></a-select>
          </template>
        </pageaddheader>
        <div class="" v-if="Object.values(userAnalysis).length > 0">

          <div class="row align-items-stretch justify-content-evenly my-3"
            style="--bs-gutter-x: 0.7rem; --bs-gutter-y: 1rem">
            <!-- 1 -->
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="dash-widget dash1 h-50 p-4 align-items-start d-block mb-0">
                <div class="dash-widgetcontent d-flex justify-content-between w-100 align-items-center ms-0 ps-0">
                  <div class="col-8">
                    <h6>Account Balance</h6>
                    <h5>
                      {{ toReadableAmount(userAnalysis.financial_report.balance) }}
                    </h5>
                  </div>
                  <div class="col-3">
                    <div class="dash-widgetimg">
                      <span><img src="../../../assets/img/icons/cash1.svg" alt="img" /></span>
                    </div>
                  </div>
                </div>
                <span class="mt-2 pointer badges bg-lightgreen p-0 user-info-label">Good</span>
              </div>
              <div class="col-12 mt-2" style="height: 45%">

                <div class="dash-widget mb-0 h-100">
                  <div class="d-flex">
                    <div class="dash-widgetcontent ms-0">
                      <h5>
                        {{
                          toReadableAmount(
                            userAnalysis.financial_report.total_debits, true
                          )
                        }}
                      </h5>
                      <h6>Total Debit</h6>
                    </div>
                    <div class="vertical-line mx-4">
                      <div class="card h-100"></div>
                    </div>
                    <div class="dash-widgetcontent ms-0">
                      <h5>
                        {{
                          toReadableAmount(
                            userAnalysis.financial_report.total_deposits, true
                          )
                        }}
                      </h5>
                      <h6>Total Credit</h6>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- 2 -->

            <div class="col-3">
              <div class="dash-widget" style="margin: 0 0 10px; height: 50%">
                <div class="dash-widgetimg">
                  <span><img src="../../../assets/img/icons/expense.svg" alt="img" /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>
                    {{ toReadableAmount(userAnalysis.financial_report.due) }}
                  </h5>
                  <h6>Due & Debt Health</h6>
                  <span class="mt-2 pointer badges bg-lightred p-0 user-info-label">
                    {{ userAnalysis.financial_report.debt_health }}</span>
                </div>
              </div>

              <div class="dash-widget" style="margin: 0 0 10px; height: 45%">
                <div class="d-flex">
                  <div class="dash-widgetcontent ms-0">
                    <h5>
                      {{
                        toReadableAmount(
                          userAnalysis.financial_report.credit_summary.available_credits
                        )
                      }}
                    </h5>
                    <h6>Credit Remaining</h6>
                  </div>
                  <div class="vertical-line mx-4">
                    <div class="card h-100"></div>
                  </div>
                  <div class="dash-widgetcontent ms-0">
                    <h5>
                      {{
                        toReadableAmount(
                          userAnalysis.financial_report.credit_summary.credit_used
                        )
                      }}
                    </h5>
                    <h6>Credit Used</h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- 3 -->
            <div class="col-3">
              <div class="dash-widget dash2" style="margin: 0 0 10px; height: 50%">
                <div class="dash-widgetimg">
                  <span style="background: #ca42ad33"><img src="../../../assets/img/icons/report.svg"
                      alt="img" /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>
                    {{ toReadableAmount(userAnalysis.financial_report.completed_orders) }}
                  </h5>
                  <h6>Completed Orders</h6>
                </div>
              </div>
              <!-- 4 -->
              <div class="dash-widget" style="margin: 0 0 10px; height: 45%">
                <div class="dash-widgetimg">
                  <span><img src="../../../assets/img/icons/order.svg" alt="img" /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>
                    {{ toReadableAmount(userAnalysis.financial_report.pending_orders) }}
                  </h5>
                  <h6>Pending Orders</h6>
                </div>
              </div>
            </div>
            <div class="col-3">
              <!-- 5 -->
              <div class="dash-widget" style="margin: 0 0 10px; height: 50%">
                <div class="dash-widgetimg">
                  <span><img src="../../../assets/img/icons/purchase.svg" alt="img" /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>
                    {{ toReadableAmount(userAnalysis.financial_report.total_orders) }}
                  </h5>
                  <h6>Total Orders</h6>
                </div>
              </div>
              <!-- 6 -->
              <div class="dash-widget dash2" style="margin: 0 0 10px; height: 45%">
                <div class="dash-widgetimg">
                  <span><img src="../../../assets/img/icons/dash3.svg" alt="img" /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>
                    {{ toReadableAmount(userAnalysis.financial_report.total_payment) }}
                  </h5>
                  <h6>Total Payment</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-stretch justify-content-evenly my-0"
            style="--bs-gutter-x: 0.7rem; --bs-gutter-y: 0rem">
            <!-- 7 -->
            <div class="col-9">
              <div class="card h-100">
                <div class="card-header">
                  <h5 class="card-title">Balance Flow</h5>
                </div>
                <div class="card-body">
                  <apexchart type="area" height="350" :options="splineAreaChart.slinearea"
                    :series="splineAreaChart.series">
                  </apexchart>
                </div>
              </div>
            </div>

            <!-- 8 -->
            <div class="col-3">
              <div class="card h-100">
                <div class="card-body text-center">
                  <img class="avatar rounded-circle mb-3" :src="userAnalysis.user.profile_picture" alt="" />
                  <h6>{{ userAnalysis.user.name }}</h6>
                  <span class="pointer badges bg-lightgreen p-0 user-info-label">
                    {{ userAnalysis.user.account_type }}
                  </span>

                  <div class="d-flex justify-content-evenly pt-5">
                    <div class="col-5">
                      <div class="d-flex align-items-center gap-1">
                        <vue-feather type="activity"></vue-feather>
                        <div class="text-start">
                          <span class="user-info-value">{{
                            userAnalysis.user.average_order
                          }}</span><br />
                          <span class="user-info-label">Average Order</span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-line">
                      <div class="card h-100"></div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex align-items-center gap-3">
                        <vue-feather type="star"></vue-feather>
                        <div class="text-start">
                          <span class="user-info-value">{{
                            userAnalysis.user.rating
                          }}</span><br />
                          <span class="user-info-label">Rating</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-evenly pt-5">
                    <div class="col-5">
                      <div class="d-flex align-items-center gap-2">
                        <vue-feather type="activity"></vue-feather>
                        <div class="text-start">
                          <span class="user-info-value">{{
                            userAnalysis.user.total_transactions
                          }}</span><br />
                          <span class="user-info-label">Transactions</span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-line">
                      <div class="card h-100"></div>
                    </div>
                    <div class="col-4">
                      <div class="d-flex align-items-center gap-3">
                        <vue-feather type="star"></vue-feather>
                        <div class="text-start">
                          <span class="user-info-value">3.5</span><br />
                          <span class="user-info-label">Rating</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-stretch justify-content-evenly my-3"
            style="--bs-gutter-x: 0.7rem; --bs-gutter-y: 0rem">
            <!-- 9 -->
            <div class="col-12">
              <div class="card h-100">
                <div class="card-header">
                  <h5 class="card-title">Orders and Payment</h5>

                  <div class="d-flex gap-3">
                    <vue-select style="width: 200px" v-model="orderChartPayload.data.year"
                      :options="orderChartPayload.selectData.years" placeholder="Select a Year" />

                    <vue-select style="width: 200px" v-model="orderChartPayload.data.month"
                      :options="orderChartPayload.selectData.months" placeholder="Select a Month" />

                    <vue-select style="width: 200px" v-model="orderChartPayload.data.day"
                      :options="orderChartPayload.selectData.days" placeholder="Select a day" />

                    <button class="btn btn-primary" @click="fetchUserOrderAndPayment()">
                      Fetch
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <apexchart type="bar" height="350" :options="orderAndPaymentChartData.scol"
                    :series="orderAndPaymentChartData.series">
                  </apexchart>
                </div>
              </div>
            </div>
            <!-- 10 -->
            <div class="col-3 d-none">
              <f-table @pageChange="getOrders($event)" :paginationConfig="paginationConfig"
                :filters="['Amount', 'customer_name']" :columns="depositHistoryColumns" :data="data"
                extra-class="w-100 mb-0" search-width="auto">
                <template #top>
                  <h5 class="card-title py-2 mb-5">Deposit History</h5>
                </template>
              </f-table>
              <!-- <div class="card h-100">
                                <div class="card-header">
                                    <h5 class="card-title">Deposit History</h5>
                                </div>
                                <div class="card-body">
                                </div>
                            </div> -->
            </div>
          </div>

          <div class="d-flex gap-1 justify-content-between mt-5 mb-3 w-100">
            <h5>Order Summary</h5>
            <div class="d-flex gap-2">
              <a-range-picker class="" @change="onDateChange" />
              <button class="btn btn-primary" @click="getUserOrder">Fetch</button>
            </div>
          </div>

          <div class="row align-items-stretch justify-content-evenly my-3"
            style="--bs-gutter-x: 0.7rem; --bs-gutter-y: 0rem">
            <!-- 9 -->
            <div class="col-12">
              <f-table :filters="['order_reference', 'customer_name']" :columns="userOrderHistory.columns"
                :data="userOrderHistory.data" extra-class="w-100 mb-0">
                <template #top>
                  <h5 class="card-title py-2 mb-5">Order List</h5>
                </template>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'status'">
                    <span class="pointer badges bg-lightgreen" v-if="record.Status === 'Completed'">{{ record.Status
                      }}</span>
                    <span class="pointer badges bg-lightgrey" v-else-if="record.Status === 'Pending'">{{ record.Status
                      }}</span>
                    <span class="pointer badges bg-lightyellow" v-else>{{
                      record.Status
                    }}</span></template>

                  <template v-if="column.key === 'order_reference'">
                    <router-link title="Click to View Order" :to="'orders/' + record.id">{{ record["order_reference"]
                      }}</router-link>
                  </template>

                  <template v-if="column.key === 'action'">
                    <a v-if="hasAccess('delete-an-order')" class="me-3 confirm-text" @click="deleteOrder(record.id)"
                      href="javascript:void(0);">
                      <img src="../../../assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </f-table>
            </div>
            <!-- 10 -->
            <div class="col-3 d-none">
              <div class="card h-100">
                <div class="card-body"></div>
              </div>
            </div>
          </div>

          <div class="d-flex gap-1 justify-content-between mt-5 mb-3 w-100">
            <h5>Transaction Overview</h5>
            <div class="d-flex gap-2">
              <a-range-picker class="" @change="onTransactionOverviewDateChange" />
              <button class="btn btn-primary" @click="fetchUserTransactionOverview">Fetch</button>
            </div>
          </div>

          <div class="row align-items-stretch justify-content-evenly my-3"
            style="--bs-gutter-x: 0.7rem; --bs-gutter-y: 0rem">
            <!-- 9 -->
            <div class="row">
              <div class="col-6">

                <f-table :filters="['amount']" :columns="userTransactionOverview.columns"
                  :data="userTransactionOverview.credits" extra-class="w-100 mb-0">
                  <template #top>
                    <h5 class="card-title py-2 mb-5">Credit History</h5>
                  </template>
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'amount'">
                      {{ formatAmount(record.amount, true) }}
                    </template>

                    <template v-if="column.key == 'date'">
                      {{ formatTimeStamp(record.date, true) }}
                    </template>
                  </template>
                </f-table>
              </div>
              <div class="col-6">
                <f-table :filters="['amount']" :columns="userTransactionOverview.columns"
                  :data="userTransactionOverview.debits" extra-class="w-100 mb-0">
                  <template #top>
                    <h5 class="card-title py-2 mb-5">Debit History</h5>
                  </template>
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'amount'">
                      {{ formatAmount(record.amount, true) }}
                    </template>

                    <template v-if="column.key == 'date'">
                      {{ formatTimeStamp(record.date, true) }}
                    </template>
                  </template>
                </f-table>
              </div>
            </div>
            <!-- 10 -->
            <div class="col-3 d-none">
              <div class="card h-100">
                <div class="card-body"></div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!user.accountNumber" class="">
          <div class="no-data-card">
            <svg viewBox="0 0 100 100">
              <path d="M50 50L10 70L90 30z" />
            </svg>
            <h2>No User Selected</h2>
            <p>Please Select a User from the Box on the right.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  basicAreaChart,
  splineAreaChart,
  orderAndPayment,
  formOrderAndPaymentChartData,
  scolstackedAreaChart,
  sbarAreaChart,
  mixedchartAreaChart,
  donutAreaChart,
  radialAreaChart,
  formSplineAreaChart,
} from "./user-report";

import store from "@/store";
import { startYear } from "@/constants";
import { formatAmount, formatTimeStamp, getMonthName, range } from "@/helpers";

const columns = [
  {
    sorter: true,
  },

  {
    title: "Order  number",
    dataIndex: "order_reference",
    key: "order_reference",
    sorter: (a, b) => a["order_reference"].localeCompare(b["order_reference"]),
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    sorter: {
      compare: (a, b) => {
        a = a.customer_name.toLowerCase();
        b = b.customer_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
  },
];
const depositHistoryColumns = [
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const transactionOverviewColumns = [
  {
    title: "Amount",
    key: "amount",
    sorter: {
      compare: (a, b) => {
        a = a.amount.toLowerCase();
        b = b.amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    key: "date",
    sorter: {
      compare: (a, b) => {
        a = a.date.toLowerCase();
        b = b.date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];;
export default {
  name: "user-report",
  data() {
    return {
      formatTimeStamp,
      formatAmount,
      title: "User Report",
      title1: "View Users Report",
      users: [],
      user: {
        accountNumber: "",
      },
      orderChartPayload: {
        selectData: {
          years: [],
          months: [],
          days: [],
        },
        data: {
          year: null,
          month: null,
          day: null,
        },
      },
      basicAreaChart: basicAreaChart,
      splineAreaChart: formSplineAreaChart([], []),
      orderAndPaymentChartData: formOrderAndPaymentChartData([], [], []),
      scolstackedAreaChart: scolstackedAreaChart,
      sbarAreaChart: sbarAreaChart,
      mixedchartAreaChart: mixedchartAreaChart,
      donutAreaChart: donutAreaChart,
      radialAreaChart: radialAreaChart,
      name: "/index",
      depositHistoryColumns,
      data: [],
      filtered: [],
      paginationConfig: {},
      userAnalysis: {},
      userOrderHistory: {
        data: [],
        columns,
        dateRange: {},
      },
      userTransactionOverview: {
        columns: transactionOverviewColumns,
        dateRange: {
          start_date: null,
          end_date: null,
        },
        credits: [],
        debits: [],
      }
    };
  },

  computed: {
    ...mapGetters({
      hasAccess: "hasAccess",
      toReadableAmount: "toReadableAmount",
    }),
  },
  methods: {
    ...mapActions({
      _getUsers: "users/getIds",
      _checkInputValidity: "checkInputValidity",
      _getOrders: "orders/get",
      _deleteOrder: "orders/delete",
    }),
    onDateChange(context, dates) {
      this.userOrderHistory.dateRange.startDate = dates[0];
      this.userOrderHistory.dateRange.endDate = dates[1];

    },
    onTransactionOverviewDateChange(context, dates) {
      this.userTransactionOverview.dateRange.start_date = dates[0];
      this.userTransactionOverview.dateRange.end_date = dates[1];

    },
    getUserOrder() {
      store
        .dispatch("users/fetchUserOrderHistory", {
          accountNumber: this.user.accountNumber,
          dateRange: this.userOrderHistory.dateRange,
        })
        .then((res) => {
          this.userOrderHistory.data = res.data.data.map((order) => {
            return {
              id: order.id,
              order_reference: order.reference,
              customer_name: order.user.name,
              date: order.date,
              Amount: order.total,
              Status: order.status,
            };
          });
        });
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    fetchUserAnalysis() {
      store
        .dispatch("users/fetchUserAnalysis", { accountNumber: this.user.accountNumber })
        .then((res) => {
          this.userAnalysis = res.data.data;

          let balanceFlowChartData = this.userAnalysis.financial_report.balance_flow
            .balance;
          let balanceFlowChartDates = this.userAnalysis.financial_report.balance_flow
            .date;

          this.splineAreaChart = formSplineAreaChart(
            balanceFlowChartDates,
            balanceFlowChartData
          );
        });
    },
    fetchUserOrderAndPayment() {
      let accountNumber = this.user.accountNumber;
      let payload = {
        year: Number.parseInt(this.orderChartPayload.data.year) || null,
        month: Number.parseInt(this.orderChartPayload.data.month) || null,
        day: Number.parseInt(this.orderChartPayload.data.day) || null,
      };
      store
        .dispatch("users/fetchUserOrderAndPayment", {
          accountNumber: accountNumber,
          payload: payload,
        })
        .then((res) => {
          this.orderAndPayment = res.data.data;

          let orders = this.orderAndPayment.map((data) => data.orders);
          let payments = this.orderAndPayment.map((data) => data.payments);
          let dates = this.orderAndPayment.map((data) => {
            return data.label;
          });

          this.orderAndPaymentChartData = formOrderAndPaymentChartData(
            orders,
            payments,
            dates
          );
        });
    },
    fetchUserTransactionOverview() {
      let accountNumber = this.user.accountNumber;

      store
        .dispatch("users/fetchUserTransactionOverview", {
          accountNumber: accountNumber,
          payload: this.userTransactionOverview.dateRange,
        })
        .then((res) => {
          const transactionOverview = res.data.data;

          this.userTransactionOverview.debits = transactionOverview.debits
          this.userTransactionOverview.credits = transactionOverview.credits
        });
    },
    fetchUserData(accountNumber) {
      this.fetchUserAnalysis(accountNumber);
      this.fetchUserOrderAndPayment();
      this.getUserOrder();
    },

    getUsers() {
      this._getUsers().then((response) => {
        this.users = response.data.data.map((user) => {
          return {
            value: user.account_number,
            label: user.name,
          };
        });
      });
    },

    deleteOrder(orderId) {
      store.commit("confirm", {
        title: "Order is About to be deleted",
        message: "Do you Really want to do this?",
        callback: (isConfrimed) => {
          if (isConfrimed) {
            this._deleteOrder({ orderId: orderId }).then((res) => {
              store.commit("notify", {
                type: "success",
                title: "Order Delete",
                message: "Order Deleted Successfully",
              });
              this.getOrders();
            });
          }
        },
      });
    },
    loadsDates() {
      // this loads the dates for order and payment chart
      // this.orderChartPayload.selectData.years = Math.
      const endYear = new Date().getFullYear();

      this.orderChartPayload.selectData.years = [];
      this.orderChartPayload.selectData.months = [{ id: 0, text: "None" }];

      for (let year = startYear; year <= endYear; year++) {
        this.orderChartPayload.selectData.years.push(year);
      }

      // Loop through Months:
      for (let month = 0; month < 12; month++) {
        this.orderChartPayload.selectData.months.push({
          id: month + 1,
          text: getMonthName(month),
        });
      }
    },
    loadDays(month) {
      // if month is selected get days
      const daysInMonth = new Date(this.orderChartPayload.data.year, month, 0).getDate(); // Get days in the month (month + 1 for correct index)
      this.orderChartPayload.selectData.days = [
        { id: 0, text: "None" },
        ...range(1, daysInMonth),
      ];
    },
  },

  mounted() {
    this.getUsers();
    this.loadsDates();
    if (!this.hasAccess("create-orders")) {
      this.items = [];
    }
  },
  watch: {
    "orderChartPayload.data": {
      handler(data) {
        this.loadDays(data.month);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 16px !important;
  font-weight: lighter !important;
}

.user-info-value {
  font-size: 16px;
  font-weight: bold;
}

.user-info-label {
  font-size: smaller !important;
}

.avatar {
  height: 150px !important;
  width: 150px !important;
  object-fit: cover;
}

.vertical-line {
  width: 1px;
}

.counter-l {
  font-size: 35px !important;
}

.no-data-card {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 20px auto;
  width: 400px;
  font-family: sans-serif;
}

.no-data-card h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.no-data-card p {
  font-size: 0.9em;
  color: #888;
}

.no-data-card svg {
  width: 80px;
  height: 80px;
  fill: #ccc;
  margin-bottom: 15px;
}
</style>
