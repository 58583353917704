export const sleep = async (seconds = 5) =>
  await new Promise((resolved) =>
    setTimeout(resolved("continue"), seconds * 1000)
  );
export const shortenString = (str, maxLength) =>
  str.length <= maxLength ? str : str.slice(0, maxLength) + "...";
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatAmount = (amount = 0, addNairaSign = false) => {
  amount = (amount ?? 0).toString().replace(",", "");
  return (
    (addNairaSign ? "₦" : "") +
    parseFloat(amount || 0).toLocaleString("en-Us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export const toReadableAmount = (num) => {
  const numAbs = Math.abs(num);
  const abbreviations = ["K", "M", "B", "T"];
  const ranges = [1e3, 1e6, 1e9, 1e12];

  for (let i = abbreviations.length - 1; i >= 0; i--) {
    const range = ranges[i];
    if (numAbs >= range) {
      return "₦" + (num / range).toFixed(2) + abbreviations[i];
    }
  }

  return num.toString();
};
export function range(start, end, step = 1) {
  const result = [];
  if (step > 0) {
    for (let i = start; i <= end; i += step) {
      result.push(i);
    }
  } else if (step < 0) {
    for (let i = start; i >= end; i += step) {
      result.push(i);
    }
  } // If step is 0, return an empty array
  return result;
}

export function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month];
}
export const formatTimeStamp = (timestamp, showTime = true) => {
  return new Date(timestamp).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...(showTime
      ? {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      : {}),
  });
};

export function dateIsToday(date) {
  var givenDate = date ? new Date(date) : new Date();
  var currentDate = new Date();

  return (
    givenDate.getFullYear() === currentDate.getFullYear() &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getDate() === currentDate.getDate()
  );
}

// Function to set the access token
export function setAccessToken(token) {
  localStorage.setItem("token", btoa(token));
}

// Function to get the access token
export function getAccessToken() {
  return localStorage.getItem("token")
    ? atob(localStorage.getItem("token"))
    : false;
}

/** Determines if the admin is logged in or not */
export function isAuth() {
  return !!getAccessToken();
}
export function notifyBrowser(title, message, callbackUrl = null) {
  const options = {
    body: message,
    vibrate: [200, 100, 200],
  };

  let notification = null;
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    notification = new Notification(title, options);
    // …
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        notification = new Notification(title ?? "", options);
        // …
      }
    });
  }

  if (notification) {
    // Add onclick event listener to the notification
    notification.onclick = function (event) {
      if (isAuth && callbackUrl) {
        window.location = callbackUrl;
      }
    };
  }
}

export const stringToSlug = (inputString) => {
  // Replace '-' and '_' with space
  inputString = inputString.replace(/[-_]/g, " ");
  // Convert to lowercase
  inputString = inputString.toLowerCase();
  // Replace spaces with underscores
  var slug = inputString.replace(/\s+/g, "_");
  return slug;
};

/**
 * Moves an element with a given ID from one parent to another.
 *
 * @param {string} elementId - The ID of the element to be moved.
 * @param {string} newParentId - The ID of the new parent element.
 */
export function moveElement(elementId, newParentId) {
  // Get the element to be moved
  const element = document.getElementById(elementId);
  if (!element) {
    console.error(`Element with ID ${elementId} not found.`);
    return;
  }

  // Get the new parent element
  const newParent = document.getElementById(newParentId);
  if (!newParent) {
    console.error(`New parent element with ID ${newParentId} not found.`);
    return;
  }

  // Move the element to the new parent
  newParent.appendChild(element);
}

export const performCalculation = (num1, num2, operator) => {
  switch (operator) {
    case "+":
      return num1 + num2;
    case "-":
      return num1 - num2;
  }
};

function sumArrayKey(data, key) {
  if (!data) {
    return 0;
  }
  return data.reduce((total, item) => {
    return total + Number.parseInt(item[key]);
  }, 0);
}
function dataIsUnique(data, key) {
  if (data.length > 0) {
    let firstItem = data[0];
    let noneUnique = data.find((product) => {
      return product[key] != firstItem[key];
    });
    if (noneUnique) {
      return false;
    } else {
      return true;
    }
  }
}

export { sumArrayKey, dataIsUnique };
