import { api } from "@/config";

export const print = {
  namespaced: true,
  actions: {
    printOrderReceipt({ context }, orderId) {
      api
        .get(`/print/${orderId}/order`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "order_receipt.pdf");
          link.style.display = "none";
          document.body.appendChild(link);

          link.click();

          // Clean up
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    },
  },
};

