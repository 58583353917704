<template>
  <div>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar></sidebar>
      <div class="page-wrapper">
        <div class="content">
          <div class="page-header">
            <div class="page-title">
              <h4>User Settings</h4>
              <h6>Manage User Settings</h6>
            </div>
            <div class="d-flex gap-2">
              <div class="page-btn">
                <a class="btn btn-added" @click="openModal()"
                  ><img
                    src="../../../assets/img/icons/plus.svg"
                    alt="img"
                    class="me-2"
                  />Add Account Type</a
                >
              </div>
            </div>
          </div>
          <!-- /product list -->
          <div class="card">
            <div class="card-body">
              <div class="table-top">
                <div class="search-set">
                  <div class="search-input">
                    <a class="btn btn-searchset"
                      ><img
                        src="../../../assets/img/icons/search-white.svg"
                        alt="img"
                    /></a>
                    <div
                      id="DataTables_Table_0_filter"
                      class="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="Search..."
                          aria-controls="DataTables_Table_0"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="wordset">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="pdf"
                        ><img src="../../../assets/img/icons/pdf.svg" alt="img"
                      /></a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="excel"
                        ><img
                          src="../../../assets/img/icons/excel.svg"
                          alt="img"
                      /></a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="print"
                        ><img
                          src="../../../assets/img/icons/printer.svg"
                          alt="img"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="table-responsive">
                <a-table
                  :columns="columns"
                  :data-source="data"
                  :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                  }"
                  @change="onChange"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'index'">
                      {{ record.id }}
                    </template>
                    <template v-if="column.key === 'credit_limit'">
                      {{ formatAmount(record.credit_limit, true) }}
                    </template>
                    <template v-if="column.key === 'id'">
                      <div
                        class="status-toggle d-flex justify-content-between align-items-center"
                      >
                        <input
                          @change="
                            updateAccountType({
                              id: record.id,
                              status: record.status == true ? 0 : 1,
                            })
                          "
                          type="checkbox"
                          :id="record.id"
                          class="check"
                          :checked="record.status"
                          :name="record.id"
                        />
                        <label :for="record.id" class="checktoggle"
                          >checkbox</label
                        >
                      </div>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <a
                          class="me-3"
                          href="javascript:void(0);"
                          @click="openModal(record.id)"
                        >
                          <img
                            src="../../../assets/img/icons/edit.svg"
                            alt="img"
                          />
                        </a>
                        <a
                          class="me-3 confirm-text"
                          href="javascript:void(0);"
                          @click="showAlert(record.id)"
                        >
                          <img
                            src="../../../assets/img/icons/delete.svg"
                            alt="img"
                          />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <!-- /product list -->
        </div>
      </div>
    </div>
    <usersettingmodal
      @addAccountType="addAccountType"
      @updateAccountType="updateAccountType"
      :accountType2Update="accountType2Update"
    ></usersettingmodal>
  </div>
</template>
<script>
import { formatAmount } from "@/helpers";
import { mapActions } from "vuex";
const columns = [
  {
    title: "#",
    key: "index",
    sorter: true,
  },
  {
    title: "User Type",
    dataIndex: "name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "id",
    sorter: true,
  },
  {
    title: "Credit Limit",
    key: "credit_limit",
    sorter: true,
  },
  {
    title: "Action",
    key: "action",
    class: "text-end",
    sorter: true,
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      accountType2Update: null,
    };
  },
  methods: {
    ...mapActions({
      _getAccountTypes: "accountType/get",
      _addAccountType: "accountType/add",
      deleteAccountType: "accountType/delete",
      _updateAccountType: "accountType/update",
    }),
    formatAmount,
    showAlert(id) {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAccountType({ id: id }).then((response) => {
            Swal.fire("Deleted!", "Account Type has been deleted.");
            this.getAccountTypes();
          });
        }
      });
    },
    openModal(id = null) {
      this.accountType2Update = this.data.find((type) => type.id == id);
      $("#accountTypeModal").modal("show");
    },
    getAccountTypes() {
      this._getAccountTypes().then((response) => {
        this.data = response.data.data.map((account_type) => {
          return account_type;
        });
      });
    },
    addAccountType(payload) {
      this._addAccountType({ payload: payload }).then((response) => {
        Swal.fire({
          title: "Account Type Added",
          icon: "success",
        });
        this.getAccountTypes();
      });
    },
    updateAccountType(payload) {
      this._updateAccountType({
        id: payload.id,
        payload: {
          ...payload,
          can_order_for_other_users: payload.can_order_for_other_users ? 1 : 0,
        },
      }).then((response) => {
        Swal.fire("Account Type Updated", "", "success");
        this.getAccountTypes();
      });
    },
  },
  mounted() {
    this.getAccountTypes();
  },
  name: "usersettings",
};
</script>
